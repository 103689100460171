import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import { SportsSoccer, CalendarToday } from '@material-ui/icons';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/lab';
import { useState } from 'react';

function TotalProfit({
  totalGames, date, setDate, ...props
}) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Jogos de hoje
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {totalGames}
            </Typography>
          </Grid>
          <Grid item>
            <DatePicker
              disableFuture
              label="Responsive"
              views={['year', 'month', 'day']}
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
                setDatePickerOpen(!datePickerOpen);
              }}
              onClose={() => {
                setDatePickerOpen(!datePickerOpen);
              }}
              open={datePickerOpen}
              renderInput={({ inputRef, inputProps }) => (
                <IconButton
                  ref={inputRef}
                  {...inputProps}
                  onClick={() => {
                    setDatePickerOpen(!datePickerOpen);
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: indigo[600],
                      height: 56,
                      width: 56,
                    }}
                  >
                    <CalendarToday />
                  </Avatar>
                </IconButton>
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SportsSoccer />
          <br />
          <Typography
            sx={{
              mr: 1,
            }}
            variant="body2"
          >
            {format(date, "dd 'de' MMMM 'de' yyyy", { locale: pt })}
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              mr: 1,
            }}
            variant="caption"
            color="textSecondary"
          >
            clique para mudar a data
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

TotalProfit.propTypes = {
  totalGames: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
};

export default TotalProfit;
