import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';
import Logo from './Logo';

function MainNavbar(props) {
  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
      }}
      boxShadow={24}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height={200}
        {...props}
      >
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </Grid>
    </Box>
  );
}

export default MainNavbar;
