import { Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import { LocaleProvider } from './contexts/LocaleContext';
import { SocketProvider } from './contexts/SocketContext';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';

const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      {
        path: '/',
        element: (
          <SocketProvider>
            <LocaleProvider>
              <Dashboard />
            </LocaleProvider>
          </SocketProvider>
        ),
      },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
