import {
  createContext, useEffect, useContext, useState,
} from 'react';

const AuthContext = createContext({});
// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState();
  useEffect(() => {
    const storagedAuth = localStorage.getItem('@App:auth');
    if (storagedAuth) {
      setAuthenticated(JSON.parse(storagedAuth));
    }
  }, []);

  async function login(data) {
    localStorage.setItem('@App:auth', JSON.stringify(data));
  }

  function logout() {
    sessionStorage.removeItem('@App:auth');
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a Auth provider');
  }
  return context;
};
