import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { pt } from 'date-fns/locale';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const content = useRoutes(routes);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={pt}>
      <AuthProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {content}
          </ThemeProvider>
        </StyledEngineProvider>
        <ToastContainer />
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
