/* eslint-disable no-nested-ternary */
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import { useMemo, useState } from 'react';

function Matches({ matches: aux, setSelectedGame, ...props }) {
  const [sort, setSort] = useState(true);
  const matches = useMemo(() => {
    if (sort) {
      return aux.reverse();
    }
    return aux;
  }, [aux, sort]);
  return (
    <Card {...props}>
      <CardHeader title="Partidas Rastreadas" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Paritida</TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={100}
                    onClick={() => setSort(!sort)}
                    title="Sort"
                  >
                    <TableSortLabel active direction={sort ? 'desc' : 'asc'}>
                      Horário
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches.map((match) => (
                <TableRow
                  sx={{
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  hover
                  onClick={() => {
                    setSelectedGame(match);
                  }}
                  key={match.data.id}
                >
                  <TableCell>
                    {match.data.cachedMatch.localTeam.data.name}
                    {' '}
                    x
                    {' '}
                    {match.data.cachedMatch.visitorTeam.data.name}
                    <Chip
                      sx={{ ml: 1 }}
                      color={
                        match.data.result
                          ? match.data.result === 'WITH_GOAL'
                            ? 'primary'
                            : 'error'
                          : 'info'
                      }
                      label={
                        match.data.result
                          ? match.data.result === 'WITH_GOAL'
                            ? 'Houve gol!'
                            : 'Não houve gol!'
                          : 'Rastreando...'
                      }
                      size="small"
                    />
                    <Chip
                      sx={{ ml: 1 }}
                      color={
                        match.data.cachedMatch.betlink ? 'primary' : 'warning'
                      }
                      label={
                        match.data.cachedMatch.betlink ? 'Com link' : 'Sem link'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {moment(match.date).format('DD/MM/YYYY  HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
Matches.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matches: PropTypes.array,
  setSelectedGame: PropTypes.func.isRequired,
};
Matches.defaultProps = {
  matches: [],
};

export default Matches;
