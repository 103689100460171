import {
  Modal,
  Typography,
  Fade,
  Box,
  Grid,
  Container,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  Button,
  ButtonGroup,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SportsSoccer } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocale } from '../../contexts/LocaleContext';
import api from '../../services/api';

function MatchModal({
  match, open, handleClose, onChange,
}) {
  const theme = useTheme();
  const { locale } = useLocale();
  const [betlink, setBetlink] = useState(
    (() => {
      if (match) {
        if (match.data.mainMessage) {
          /**
           * @type {string}
           */
          return match.data.mainMessage.text;
        }
        /**
         * @type {string}
         */
        return match.data.content;
      }
      return '';
    })(),
  );
  useEffect(() => {
    if (match) {
      setBetlink(match.data.cachedMatch.betlink);
    }
  }, [match]);

  async function handleClearStats() {
    await api.put(`/matches/${match.id}?area=clear`);
    onChange();
  }
  async function handleUpdateStatus(status) {
    await api.put(`/matches/${match.id}`, { status });
    onChange();
  }
  async function handleUpdateBetlink() {
    await api.put(`/matches/${match.id}?area=bet`, { betlink });
    onChange();
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={!!open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!match}>
        <Grid
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
            width: '90%',
            maxWidth: 620,
            maxHeight: '90vh',
            overflow: 'scroll',
            padding: theme.spacing(2, 0),
          }}
        >
          <PerfectScrollbar>
            {match && (
              <Container maxWidth="sm">
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <img
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                      }}
                      src={
                        match && match.data.cachedMatch.localTeam.data.logo_path
                      }
                      alt={match && match.data.cachedMatch.localTeam.data.name}
                    />
                  </Grid>
                  <Grid item alignItems="center" style={{ display: 'flex' }}>
                    <Grid container>
                      <Box flex={1}>
                        <Typography align="center">
                          {match && match.data.cachedMatch.localTeam.data.name}
                        </Typography>
                      </Box>
                      <Box flex={1} paddingLeft={3} paddingRight={3}>
                        <Typography align="center">X</Typography>
                      </Box>
                      <Box flex={1}>
                        <Typography align="center">
                          {match
                            && match.data.cachedMatch.visitorTeam.data.name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <img
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: '50%',
                      }}
                      src={
                        match
                        && match.data.cachedMatch.visitorTeam.data.logo_path
                      }
                      alt={
                        match && match.data.cachedMatch.visitorTeam.data.name
                      }
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={2} justifyContent="flex-end">
                  {(() => {
                    if (match.data.result) {
                      if (match.data.result === 'WITH_GOAL') {
                        return (
                          <>
                            <Grid item>
                              <Typography align="right">Houve Gol </Typography>
                            </Grid>
                            <Grid item>
                              <SportsSoccer color="primary" />
                            </Grid>
                          </>
                        );
                      }
                      return (
                        <>
                          <Grid item>
                            <Typography align="right">
                              Não Houve Gol!
                            </Typography>
                          </Grid>
                          <Grid item>
                            <SportsSoccer color="error" />
                          </Grid>
                        </>
                      );
                    }
                    return (
                      <>
                        <Grid item>
                          <Typography align="right">Rastreando</Typography>
                        </Grid>
                        <Grid item>
                          <SportsSoccer />
                        </Grid>
                      </>
                    );
                  })()}
                </Grid>
                <br />
                <FormControl fullWidth>
                  <FormLabel>Mensagem enviada</FormLabel>
                  <TextField
                    variant="filled"
                    placeholder="Mensagem enviada"
                    fullWidth
                    multiline
                    rows={6}
                    disabled
                    value={match.data.mainMessage[locale].text}
                  />
                </FormControl>
                <br />
                <Box marginTop={2}>
                  <Grid container>
                    <FormControl fullWidth>
                      <FormLabel>Status</FormLabel>
                      <ButtonGroup
                        fullWidth
                        variant="outlined"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          onClick={() => {
                            handleUpdateStatus('WITH_GOAL');
                          }}
                          variant={
                            match.data.result === 'WITH_GOAL' && 'contained'
                          }
                        >
                          Com Gol
                        </Button>
                        <Button
                          onClick={() => {
                            handleUpdateStatus('WITHOUT_GOAL');
                          }}
                          color="error"
                          variant={
                            match.data.result === 'WITHOUT_GOAL' && 'contained'
                          }
                        >
                          Sem Gol
                        </Button>
                        <Button
                          onClick={() => {
                            handleClearStats();
                          }}
                          color="inherit"
                          variant={
                            !match.data.result ? 'contained' : 'outlined'
                          }
                        >
                          Limpar Dados
                        </Button>
                      </ButtonGroup>
                    </FormControl>
                  </Grid>
                </Box>
                <Box marginTop={2}>
                  <Grid container>
                    <TextField
                      fullWidth
                      label="link do bet 365"
                      onChange={(e) => setBetlink(e.target.value)}
                      value={betlink}
                    />
                  </Grid>
                </Box>
                <Grid container justifyContent="space-between">
                  <Box marginTop={3}>
                    <Button
                      onClick={() => {
                        handleClose();
                      }}
                      color="inherit"
                      variant="contained"
                    >
                      X
                    </Button>
                  </Box>
                  <Box marginTop={3}>
                    <Button
                      onClick={() => {
                        handleUpdateBetlink();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Atualizar Link
                    </Button>
                  </Box>
                </Grid>
              </Container>
            )}
          </PerfectScrollbar>
        </Grid>
      </Fade>
    </Modal>
  );
}

MatchModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  onChange: PropTypes.func,
};

MatchModal.defaultProps = {
  match: undefined,

  handleClose: () => { },
  open: false,

  onChange: () => { },
};

export default MatchModal;
