import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { useState } from 'react';
import PropTypes from 'prop-types';
import SummaryModal from './SummaryModal';

function DailyProgress({ date, ...props }) {
  const [modalOpen, setModalOpen] = useState(false);
  function generatePercentByDate() {
    const now = new Date().getHours();
    const endOfDate = 24;
    return (now * 100) / endOfDate;
  }
  const [percent] = useState(generatePercentByDate());
  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <SummaryModal
          date={date}
          open={modalOpen}
          progress={percent}
          handleClose={() => setModalOpen(false)}
        />
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Progresso do dia
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {percent.toFixed(2)}
              %
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: orange[600],
                height: 56,
                width: 56,
              }}
            >
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}>
          <LinearProgress value={percent} variant="determinate" />
        </Box>
        <Button
          onClick={() => setModalOpen(true)}
          sx={{ mt: 3 }}
          fullWidth
          variant="contained"
        >
          Gerar Relatório do dia
        </Button>
      </CardContent>
    </Card>
  );
}

DailyProgress.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default DailyProgress;
