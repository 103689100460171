import {
  Modal, Fade, Grid, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import GoalsGraph from './GoalsGraph';

function GoalsGraphModal({ matches, open, handleClose }) {
  const theme = useTheme();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={!!open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!open}>
        <Grid
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
            width: '90%',
            padding: theme.spacing(2, 0),
          }}
        >
          <GoalsGraph matches={matches} modal />
        </Grid>
      </Fade>
    </Modal>
  );
}

GoalsGraphModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matches: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

GoalsGraphModal.defaultProps = {

  handleClose: () => { },
  open: false,
};

export default GoalsGraphModal;
