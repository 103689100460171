/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSocket } from '../contexts/SocketContext';

function MatchListener({ matches, setMatches, children }) {
  const { socket } = useSocket();
  useEffect(() => {
    if (socket) {
      socket.on('game-sent', (data) => {
        toast.success('🚀 jogo adicionado', { position: 'top-right' });
        setMatches([...matches, data]);
      });
      socket.on('game-updated', (data) => {
        function search(match) {
          return match.id === data.id;
        }
        const index = matches.findIndex(search);
        const arr = [...matches];
        arr[index] = data;
        setMatches(arr);
        toast.success('🚀 jogo atualizado', { position: 'top-right' });
      });
      return () => {
        socket.off('game-sent');
        socket.off('game-updated');
      };
    }
    return () => socket;
  }, [socket, matches]);
  return (
    <>
      {' '}
      {children}
    </>
  );
}

export default MatchListener;
