import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
} from '@material-ui/core';
import { SportsSoccer } from '@material-ui/icons';
import PropTypes from 'prop-types';

function TrackedGames({
  withGoalMatchesPercent = 0,
  withoutGoalMatchesPercent = 0,
  ...props
}) {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: [withGoalMatchesPercent, withoutGoalMatchesPercent],
        backgroundColor: [colors.indigo[500], colors.red[600]],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ['Saíram gol', 'Não Saíram gol'],
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  const trackList = [
    {
      title: 'Saíram gol',
      value: withGoalMatchesPercent,
      icon: SportsSoccer,
      color: colors.indigo[500],
    },
    {
      title: 'Não Saíram gol',
      value: withoutGoalMatchesPercent,
      icon: SportsSoccer,
      color: colors.red[600],
    },
  ];

  return (
    <Card {...props}>
      <CardHeader title="Gols do Dia" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative',
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
          }}
        >
          {trackList.map(({
            color, icon: Icon, title, value,
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center',
              }}
            >
              <Icon color={color === '#3f51b5' ? 'primary' : 'error'} />
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h2">
                {value.toFixed(2)}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

TrackedGames.propTypes = {
  withGoalMatchesPercent: PropTypes.number.isRequired,
  withoutGoalMatchesPercent: PropTypes.number.isRequired,
};

export default TrackedGames;
