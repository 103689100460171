import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { useLocale } from '../../contexts/LocaleContext';
import brazilFlag from '../../assets/images/BR_FLAG.svg';
import spainFlag from '../../assets/images/ES_FLAG.svg';

function Locale(props) {
  const { locale, toggleLocale } = useLocale();
  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Rodando em
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {locale.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                toggleLocale();
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: red[600],
                  height: 56,
                  width: 56,
                }}
                src={locale === 'pt' ? brazilFlag : spainFlag}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              mr: 1,
            }}
            variant="caption"
            color="textSecondary"
          >
            Os jogos e mensagens editados refletirão diretamente nos grupos
            {' '}
            {locale === 'pt' ? 'Brasileiros' : 'Espanicos'}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Locale;
