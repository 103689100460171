import React from 'react';
import io from 'socket.io-client';

const SocketContext = React.createContext();

// eslint-disable-next-line react/prop-types
export function SocketProvider({ children }) {
  const [socket, setSocket] = React.useState(null);

  React.useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
      query: {
        id: '1',
      },
    });
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
}

export function useSocket() {
  const context = React.useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must to be used within a socketProvider.');
  }

  return context;
}
