import {
  createContext, useCallback, useContext, useState,
} from 'react';

const LocaleContext = createContext({});
// eslint-disable-next-line react/prop-types
export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState('pt');

  const toggleLocale = useCallback(() => {
    if (locale === 'pt') {
      setLocale('es');
    } else {
      setLocale('pt');
    }
  }, [locale, setLocale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        toggleLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}
export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a Locale provider');
  }
  return context;
};
