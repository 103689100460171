import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function GoalsGraph({
  matches, openOverView, modal = false, ...props
}) {
  const theme = useTheme();
  const matchesByHour = useMemo(() => {
    const obj = {};
    for (let i = 0; i < 24; i += 1) {
      const withGoals = matches.filter((m) => (
        m.data.result === 'WITH_GOAL' && new Date(m.date).getHours() === i
      )).length;
      const withoutGoals = matches.filter((m) => (
        m.data.result === 'WITHOUT_GOAL' && new Date(m.date).getHours() === i
      )).length;
      obj[`${`0${i}`.slice(-2)}:00`] = {
        withGoals,
        withoutGoals,
      };
    }
    return obj;
  }, [matches]);

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: Object.values(matchesByHour).map((m) => m.withGoals),
        label: 'Houve gol',
        maxBarThickness: 10,
      },
      {
        backgroundColor: colors.red[400],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: Object.values(matchesByHour).map((m) => m.withoutGoals),
        label: 'Não houve gol',
        maxBarThickness: 10,
      },
    ],
    labels: Object.keys(matchesByHour),
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card {...props}>
      <CardHeader title="Relação de partidas durante o dia" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative',
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      {!modal && (
        <>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
            }}
          >
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={openOverView}
            >
              Expandir
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
}

GoalsGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  matches: PropTypes.array,
  openOverView: PropTypes.func,
  modal: PropTypes.bool,
};

GoalsGraph.defaultProps = {
  matches: [],

  openOverView: () => { },
  modal: false,
};

export default GoalsGraph;
