import {
  Modal,
  Typography,
  Fade,
  Grid,
  Container,
  useTheme,
  TextField,
  FormControl,
  FormLabel,
  Button,
} from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { orange } from '@material-ui/core/colors';
import api from '../../services/api';

function SummaryModal({
  open, date, handleClose, progress,
}) {
  const [goalMessages, setGoalMessages] = useState();
  const MySwal = withReactContent(Swal);
  async function handleGenerateDailyMessage() {
    await api.post(`matches/${date.toISOString()}/summary`);
  }
  async function handleFetchSummaryData() {
    const { data } = await api.get(`matches/${date.toISOString()}/summary`);
    setGoalMessages(data);
  }

  useEffect(() => {
    if (open) {
      handleFetchSummaryData();
    }
  }, [open]);

  const theme = useTheme();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={!!open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!open}>
        <Grid
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: theme.shadows[5],
            width: '90%',
            maxWidth: 620,
            minHeight: 480,
            padding: theme.spacing(2, 0),
          }}
        >
          <Container maxWidth="sm">
            {goalMessages && (
              <>
                <Typography variant="h4">Resumo do dia</Typography>
                <br />
                <Typography variant="caption">mensagens geradas</Typography>
                <Grid sx={{ mt: 1 }}>
                  {Object.keys(goalMessages).map((lang) => (
                    <>
                      <br />
                      <FormControl fullWidth>
                        <FormLabel>
                          Mensagem gerada:
                          {lang}
                        </FormLabel>
                        <TextField
                          variant="filled"
                          placeholder={`Mensagem gerada: ${lang}`}
                          fullWidth
                          multiline
                          rows={5}
                          disabled
                          value={goalMessages[lang]}
                        />
                      </FormControl>
                    </>
                  ))}
                </Grid>
                <Button
                  onClick={() => {
                    handleClose();
                    MySwal.fire({
                      title: `Publicar resumo${progress < 80
                        && ' (esse não é um horário propício para o envio)'}`,
                      icon: 'warning',
                      iconColor: orange[600],
                      focusCancel: true,
                      showCancelButton: true,
                      confirmButtonText: 'Publicar',
                      confirmButtonAriaLabel: 'Publicar',
                      confirmButtonColor: theme.palette.primary.main,
                      cancelButtonText: 'Cancelar',
                      cancelButtonAriaLabel: 'Cancelar',
                      cancelButtonColor: theme.palette.error.main,
                      background: theme.palette.background.paper,
                      html: `<p>Você tem certeza da publicação da página ?</p>
              <style>
                .swal2-title {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
               .swal2-html-container {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
              </style>`,
                    }).then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        try {
                          MySwal.fire({
                            title: 'Resumo publicado com sucesso',
                            icon: 'success',
                            iconColor: 'green',
                            confirmButtonColor: theme.palette.primary.main,
                            background: theme.palette.background.paper,
                            html: `<style>
                .swal2-title {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
               .swal2-html-container {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
              </style>`,
                          });
                          handleGenerateDailyMessage();
                        } catch (error) {
                          MySwal({
                            title: 'Error publicando o resumo',
                            icon: 'error',
                          });
                        }
                      }
                    });
                  }}
                >
                  Enviar para o Telegram
                </Button>
              </>
            )}
          </Container>
        </Grid>
      </Fade>
    </Modal>
  );
}

SummaryModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  progress: PropTypes.number.isRequired,
};

SummaryModal.defaultProps = {

  handleClose: () => { },
  open: false,
};

export default SummaryModal;
