import { Helmet } from 'react-helmet';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Backdrop,
  useTheme,
} from '@material-ui/core';
import { useState, useEffect, useMemo } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import MatchListener from '../components/MatchListener';
import GoalsGraphModal from '../components/dashboard/GoalsGraphModal';
import MatchModal from '../components/dashboard/MatchModal';
import Locale from '../components/dashboard/Locale';
import Matches from '../components/dashboard/Matches';
import GoalsGraph from '../components/dashboard/GoalsGraph';
import DailyProgress from '../components/dashboard/DailyProgress';
import TotalGames from '../components/dashboard/TotalGames';
import TrackedGames from '../components/dashboard/TrackedGames';
import api from '../services/api';

function Dashboard() {
  const MySwal = withReactContent(Swal);
  const [preGames, setGames] = useState([]);
  const games = useMemo(
    () => preGames.sort((a, b) => {
      if (a.date > b.date) {
        return 1;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 0;
    }),
    [preGames],
  );
  const theme = useTheme();
  const [initiated, setInitiated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [goalsModalOpen, setGoalsModalOpen] = useState(false);
  const gamesWithGoal = useMemo(
    () => games.filter((g) => g.data.result === 'WITH_GOAL'),
    [games],
  );
  const gamesWithoutGoal = useMemo(
    () => games.filter((g) => g.data.result === 'WITHOUT_GOAL'),
    [games],
  );
  function callPassword() {
    MySwal.fire({
      title: 'Insira a senha',
      input: 'password',
      inputPlaceholder: 'Aqui vem a senha',
      background: theme.palette.background.default,
      confirmButtonText: 'Entrar',
      confirmButtonColor: theme.palette.primary.main,
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      html: `
      <p>Senha super secreta</p>
      <style>
                .swal2-title {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
               .swal2-html-container {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
              </style>`,
    }).then(({ value: password }) => {
      if (password === process.env.REACT_APP_DASH_PASS) {
        setInitiated(true);
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Senha incorreta!',
          background: theme.palette.background.default,
          confirmButtonColor: theme.palette.primary.main,
          html: `<style>
                .swal2-title {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
               .swal2-html-container {
                  font-family: Roboto, Helvetica Neue, sans-serif;
                }
              </style>`,
        }).then(() => callPassword());
      }
    });
  }
  useEffect(() => {
    if (theme) {
      callPassword();
    }
  }, [theme]);
  const [selectedGame, setSelectedGame] = useState();
  const [date, setDate] = useState(new Date());
  async function handleGetPageGames() {
    try {
      setLoading(true);
      const { data } = await api.get(`/matches?date=${date.toISOString()}`);
      setLoading(false);
      setGames(data);
    } catch (err) {
      setLoading(true);
    }
  }
  useEffect(() => {
    if (initiated) {
      handleGetPageGames();
    }
  }, [date, initiated]);
  return (
    <>
      <Helmet>
        <title>Dashboard | Betzord</title>
      </Helmet>
      <MatchListener matches={games} setMatches={setGames} />
      <MatchModal
        onChange={() => {
          setSelectedGame(undefined);
          handleGetPageGames();
        }}
        match={selectedGame}
        open={!!selectedGame}
        handleClose={() => {
          setSelectedGame(undefined);
        }}
      />
      <GoalsGraphModal
        matches={games}
        open={goalsModalOpen}
        handleClose={() => {
          setGoalsModalOpen(false);
        }}
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Backdrop open={loading} sx={{ zIndex: 100 }}>
          <CircularProgress size="5rem" />
        </Backdrop>
        <Container>
          <Grid container spacing={3}>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <Locale />
            </Grid>
            <Grid item lg={4} sm={6} xl={3} xs={12}>
              <TotalGames
                date={date}
                setDate={setDate}
                totalGames={games.length}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item lg={4} sm={12} xl={3} xs={12}>
              <DailyProgress date={date} />
            </Grid>
            <Grid item lg={8} md={6} xl={9} xs={12}>
              <GoalsGraph
                matches={games}
                openOverView={() => setGoalsModalOpen(true)}
              />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <TrackedGames
                withGoalMatchesPercent={
                  Number.isNaN(gamesWithGoal.length / gamesWithoutGoal.length)
                    ? 0
                    : (gamesWithGoal.length * 100)
                    / (gamesWithGoal.length + gamesWithoutGoal.length)
                }
                withoutGoalMatchesPercent={
                  Number.isNaN(gamesWithGoal.length / gamesWithoutGoal.length)
                    ? 0
                    : (gamesWithoutGoal.length * 100)
                    / (gamesWithGoal.length + gamesWithoutGoal.length)
                }
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Matches matches={games} setSelectedGame={setSelectedGame} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Dashboard;
