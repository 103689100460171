import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import MainNavbar from './MainNavbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  width: '100%',
}));

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
});

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  paddingBottom: '3rem',
  width: '100%',
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
});

function MainLayout() {
  return (
    <MainLayoutRoot>
      <MainNavbar />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
}

export default MainLayout;
